<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        href="/login"
      >
        <app-logo />

        <h2 class="brand-text text-primary ml-1">
          Engkid
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Forgot password-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          lg="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-2">
            Forgot Password? 🔒
          </b-card-title>

          <!-- form -->
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="mb-3"
            @on-complete="setPassword"
          >
            <!-- account detail tab -->
            <tab-content
              :before-change="forgetPassword"
              title="Enter Email"
              icon="feather icon-mail"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Enter Your Email Here!"
                    label-for="i-email"
                  >
                    <b-form-input
                      id="i-email"
                      v-model="userEmail"
                      name="email"
                      placeholder="example@gmail.com"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>

            <!-- Enter Code -->
            <tab-content
              :before-change="verifyCode"
              title="Enter Code"
              icon="feather icon-code"
            >
              <b-row>
                <b-col md="12" class="mb-3">
                  <div><small class="text-muted">Enter Your Verify Code Here.</small></div>
                  <pincode-input
                    v-model="code"
                    placeholder="0"
                    :character-preview="false"
                    :length="6"
                  />
                  <b-form-invalid-feedback
                    v-if="error"
                    tooltip
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </tab-content>

            <!-- Enter New Password -->
            <tab-content
              title="Enter New Password"
              icon="feather icon-check-circle"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Enter Your New Password Here!"
                    label-for="i-password"
                  >
                    <b-form-input
                      id="i-password"
                      v-model="newPassword"
                      name="password"
                      placeholder="secret"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>

          </form-wizard>
          <!-- end form -->

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import PincodeInput from 'vue-pincode-input'
import AppLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'

import service from '../service'

export default {
  components: {
    AppLogo,
    FormWizard,
    TabContent,
    PincodeInput,
    // ValidationProvider,
    // ValidationObserver,
  },
  data() {
    return {
      userEmail: null,
      code: '',
      newPassword: null,
      error: null,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      verify_token: null,
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async forgetPassword() {
      const response = await service.forgetPassword({ email: this.userEmail })
      let is_success = response.data.type === 'SUCCESS'

      if (!is_success) {
        this.error = response.data.message
        return false
      }
      this.$store.dispatch('pushSuccessNotify', { text: 'please check your email for the verify code!' })
      this.error = null
      return true
    },
    async verifyCode() {
      let response = await service.verifyCode({
        email: this.userEmail,
        code: this.code,
      })
      if (response.data.type !== 'DATA') {
        this.error = response.data.key
        return false
      }
      this.verify_token = response.data.data.verify_token
      this.$store.dispatch('pushSuccessNotify', { text: 'successfully verified code!' })
      this.error = null
      return true
    },
    async setPassword() {
      const response = await service.setNewPassword({
        token: this.verify_token,
        password: this.newPassword,
        email: this.userEmail,
      })

      if (response.data.type !== 'SUCCESS') {
        this.error = response.data.message
        return false
      }
      this.$store.dispatch('pushSuccessNotify', { text: response.data.message })
      this.error = null
      this.$router.push({ name: 'auth-login' })
      return true
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';

div.vue-pincode-input-wrapper {
  // any styles you want for wrapper
}

input.vue-pincode-input {
  margin: 6px;
  max-width: 72px;
  font-size: 1.8rem;
}
</style>
